/**
 * Gets the ranking graph from performance KPIs.
 *
 * @param {TalentIdKpi[]} performanceKpis - The performance KPIs.
 * @param {Object} graph_map - The graph map object.
 *
 * @returns {Object} The performance graph.
 */
export const getRankingGraph = (performanceKpis, { name, displayName, description, yLabel, xLabel }) => {
    const points = [];
    for (const kpi of performanceKpis) {
        let date = new Date(kpi.metadata.timestamp);
        const label = kpi.metadata.label  + " " + date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear().toString().slice(-2);;
        points.push([label, kpi.metadata.ranking, kpi.metadata.timestamp]);
    }
    return {
        name,
        displayName,
        description,
        yLabel,
        xLabel,
        points,
        type: "graphical"
    };
};
