/**
 * Retrieves the gap to fastest graph.
 *
 * @param {Object} gap_to_fastest_kpis - The gap to fastest KPIs.
 *
 * @returns {Object} The gap to fastest graph.
 */
export const getGapToFastestGraph = (gap_to_fastest_kpis) => {
    const points = { seconds: [], percentage: [] };
    for (const [event, gaps] of Object.entries(gap_to_fastest_kpis)) {
        const min_gap_kpi = gaps.reduce((min, kpi) => kpi.gapSeconds < min.gapSeconds ? kpi : min, gaps[0]);
        if (!min_gap_kpi) {
            continue;
        }
        let date = new Date(min_gap_kpi.timestamp);
        const label = min_gap_kpi.label + " " + date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear().toString().slice(-2);
        points.seconds.push([label, min_gap_kpi.gapSeconds, min_gap_kpi.timestamp]);
        points.percentage.push([label, min_gap_kpi.gapPercentage, min_gap_kpi.timestamp]);
    }
    points.seconds = points.seconds.sort((a, b) => new Date(a[2]) - new Date(b[2]));
    points.percentage = points.percentage.sort((a, b) => new Date(a[2]) - new Date(b[2]));
    return {
        name: "GapToFastest",
        displayName: "Gap To Best Lap Times",
        description: "YOUR BEST GAP TO THE FASTEST LAP AT EACH EVENT YOU HAVE PARTICIPATED IN.",
        yLabel: "Gap to Fastest",
        xLabel: "Event",
        points,
        type: "toggleableGraphical"
    };
};
